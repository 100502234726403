<template>
    <div class="detailOfTheActivity">
        <div>
            <h1 style="text-align: center;">{{ContactInfo.title}}</h1>
            <div style="margin:auto" v-html="ContactInfo.content"></div>
        </div>
    </div>
</template>

<script>
import {selectDsContactUsById} from '@/api/main.js'
export default {
    data() {
        return {
            ContactInfo:{},
            id:''
        }
    },
    mounted() {
        this.id = this.$route.query.id
       console.log(this.$route.query.id)
        this.selectDsContactUsById()
    },
    methods: {
        selectDsContactUsById(){
            selectDsContactUsById({
                id:this.id
            }).then(res=>{
                if(res.code==0){
                    if(this.isChinese()){
                       this.ContactInfo = res.data
                    }else{
                        this.ContactInfo = {
                            ...res.data,
                            title:res.data.title,
                            content:res.data.contentEng
                        }
                    }
                }else{
                    this.showMessage();
                }
            })
        }
    }
}
</script>
<style lang="scss">
.detailOfTheActivity {
    width: 1200px;
    margin: auto;
    padding: 48px 0px;
    .addbtn {
        margin-top: 30px;
        height: 50px;
        margin-right: 20px;
        // width: 210px;
        // height: 50px;
        border: 1.5px solid transparent;
        border-radius: 0;
        white-space: nowrap;
        transition: all 0.3s ease;
        border: 2px solid #000;
        text-align: center;
        padding: 15px 65px;
    }

    .addbtn:hover {
        background: #000;
        color: #fff;
        cursor: pointer;
    }
}
</style>